/////////////////////////////
// == Body
/////////////////////////////

$body-color: $gray-800;

/////////////////////////////
// == Fonts
/////////////////////////////

// Family

$font-family-sans-serif: "Poppins", -apple-system, BlinkMacSystemFont,
  "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-base: $font-family-sans-serif;

// Weight

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

// Size

$font-size-base: 1rem;
$h6-font-size: $font-size-base * 1.125;

// Heading

$headings-font-weight: $font-weight-bold;
$headings-color: $gray-900;

/////////////////////////////
// == Border
/////////////////////////////

$border-radius: 0.375rem;
$border-color: $gray-300;

/////////////////////////////
// == Spacing
/////////////////////////////

$spacer: 1rem;

/////////////////////////////
// == HR
/////////////////////////////

$hr-margin-y: 0;
$hr-border-color: $border-color;

/////////////////////////////
// == Box Shadows
/////////////////////////////

$box-shadow-sm: 0 0.125rem 0.25rem rgba($primary, 0.075) !default;
$box-shadow: 0 0.3125rem 2.5rem rgba($black, 0.15) !default;
$box-shadow-lg: 0 1rem 3rem rgba($primary, 0.175) !default;

/////////////////////////////
// == Buttons
/////////////////////////////

$btn-font-weight: $font-weight-bold;
$btn-border-radius: 0.2rem;
$input-btn-padding-y: 0.75rem;
$input-btn-padding-x: 1.6rem;

$btn-secondary-text-color: #59616a;

/////////////////////////////
// == Navbar
/////////////////////////////

$navbar-padding-y: $spacer;
$navbar-light-toggler-border-color: transparent;

/////////////////////////////
// == Navbar
/////////////////////////////

$card-border-width: 0px;

/////////////////////////////
// == Preview
/////////////////////////////

$preview-padding: 0.2rem;
$preview-bg: rgba($orange, 0.2);
$preview-color: darken($primary, 30%);
$preview-heading-margin: $spacer * 2;

/////////////////////////////
// == Footer
/////////////////////////////

$footer-bg: $gray-700;

/////////////////////////////
// == Wizard
/////////////////////////////

$wizard-header-title-bg: $white;
$wizard-header-title-border: $border-color;
$wizard-step-color: $gray-600;
$secondary-color: $primary;
$jet: blue;
$custom-radio-checkbox-border-disabled: $gray-600;
$custom-radio-checkbox-border: $gray-200;
$cta-header-button-hover: null;
$go-back-button-hover: null;

/////////////////////////////
// == Inputs
/////////////////////////////

$input-padding-x: 1rem;
$input-color: $gray-800;

/////////////////////////////
// == Background
/////////////////////////////

$background-color: $gray-100;

/////////////////////////////
// == Oragne line
/////////////////////////////

$line-height: 12px;
