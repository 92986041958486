$white: #fff;
$gray-100: #f6fdff;
$gray-200: #f8f8f8;
$gray-300: #dbdbdb;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #92879f;
$gray-700: #495057;
$gray-800: #716e75;
$gray-900: #27084d;
$black: #000;

$grays: ();
// stylelint-disable-next-line scss/dollar-variabl
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);

$blue: #007bff;
$indigo: #6f0ee6;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #fe906b;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;

$colors: ();
// stylelint-disable-next-line scss/dollar-variabl
$colors: map-merge(
  (
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "gray": $gray-600,
    "gray-dark": $gray-800
  ),
  $colors
);

$primary: $red;
$secondary: $gray-200;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;
$red: $red;
$orange: $orange;

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variabl
$theme-colors: map-merge(
  (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
    "red": $red,
    "orange": $orange
  ),
  $theme-colors
);
